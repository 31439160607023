import { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import customstyles from "../../components/customstyles/react-select-styles";
import { Button, Form, Modal } from "react-bootstrap";

import {
  getapplistbyuserid,
  deleteAppByuserID,
  getapplicationsbyid,
  getCommandpostAccountinfoByEmail,
} from "../../services/applications_services";
import { useAuth0 } from "@auth0/auth0-react";
import SweetAlert from "react-bootstrap-sweetalert";
import GenericListTable from "../../components/gridTable/genericLlistTable";
import Moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../components/Loading";
import GenericSimpleTable from "../../components/gridTable/genericSimpleTable";
import { getAppsCollectionListsByFormName } from "../../services/collection_lists_services";
import { getLOBApps } from "../../services/lob_app_info_services";
import { appConstants } from "../../constants/app_constants";
import { useLocation } from "react-router-dom";

const OAuthClient = require("intuit-oauth");
function Listapps({ userinfo, ctechapp, devenv, onHandleClose }) {
  const procoreappname = "procore",
    quickbookappname = "quickbooks",
    sharepointappname = "sharepoint",
    entraidappname = "entraid",
    commandpostappname = "commandpost",
    projectmasterappname = "projectmaster",
    contactmasterappname = "contactmaster",
    entitymasterappname = "entitymaster",
    appmasterappname = "appmaster",
    salesforceappname = "salesforce";
  const [list, setList] = useState([]);
  const [isDeleteApp, setIsDeleteApp] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [rowToDelete, setRowToDelete] = useState();
  //const [devenv, setDevEnv] = useState("");
  const [isEditApp, setIsEditApp] = useState(false);
  const [isDetailApp, setIsDetailApp] = useState(false);
  const [isWindowClose, setisWindowClose] = useState(false);
  const [isAddApp, setIsAddApp] = useState(false);
  //const ctechapp = useRealmApp();
  const { isLoading, error, isAuthenticated, user } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isShowError, setisShowError] = useState(false);
  const [isShowDuplicatevalidation, setisShowDuplicatevalidation] =
    useState(false);
  const [showGeneralSection, setshowGeneralSection] = useState(true);
  const [showAccountSection, setshowAccountSection] = useState(false);
  const [showSecuritySection, setshowSecuritySection] = useState(false);
  const [showTokenSection, setshowTokenSection] = useState(false);
  const [showEndpointSection, setshowEndpointSection] = useState(false);
  const [showMicrosoftTenantSection, setshowMicrosoftTenantSection] =
    useState(false);
  const [showSharepointSection, setshowSharepointSection] = useState(false);
  const [showEntraIDSection, setshowEntraIDSection] = useState(false);
  const [lobapps, setLOBApps] = useState([]);
  const [applist, setAppList] = useState([]);
  const [isSuccessForConnect, setisSuccessForConnect] = useState(false);
  const [isSuccessForSave, setisSuccessForSave] = useState(false);
  const [environmentlist, setEnvironmentList] = useState([]);
  const [endpointlist, setEndpointList] = useState([]);
  const [endpointtypelookuplist, setEndpointtypelookuplist] = useState([]);
  const [validatemsg, SetValidatemsg] = useState("");
  const [endpointlistmandatory, setEndpointlistmandatory] = useState("");
  const [commandpostaccountinfo, setcommandpostaccountinfo] = useState();
  const [iscommandpostaccountinfo, setiscommandpostaccountinfo] =
    useState(false);
  const [existAppForConnect, setExistAppForConnect] = useState();

  //const [isExistAppForSave, setIsExistAppForSave] = useState(false);
  const [isAppIsUpdated, setIsAppIsUpdated] = useState(false);
  const [isconnect, setisConnect] = useState("false");
  const [isendpoint, setisEndpoint] = useState("false");
  const [RelationshipList, setRelationshipList] = useState([]);
  const [isAppIsCreated, setIsAppIsCreated] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  //const [appid, setAppid] = useState("0");
  //const [iseditmodelopen, setIsEditModelOpen] = useState();
  const [ids, setIds] = useState();

  const initialappuserinfo = {
    id: "",
    userid: userinfo.userid,
    appdisplayname: "",
    apprefreshtoken: "",
    apptoken: "",
    status: "",
    is_activeuser: "true",
  };
  const [appuserinforow, setAppuserinforow] = useState(initialappuserinfo);

  const initialapiendpoints = {
    id: "",
    userid: userinfo.userid,
    endpointtype: "",
    endpointname: "",
    endpointvalue: "",
    endpointsecret: "",
    endpointminval: "",
  };
  const [apiendpointrow, setApiendpointrow] = useState(initialapiendpoints);

  const initialappstate = {
    _id: "",
    appname: "",
    appenvironment: "",
    apprelationship: "Child",
    appautoapproval: "false",
    isSubCustomerAllowed: false,
    appurl: "",
    appclientid: "",
    appclientsecret: "",
    appredirecturi: "",
    appresthostname: "",
    appaccesstokenhostname: "",
    appaccountid: "",
    tenantid: "",
    tenantname: "",
    siteid: "",
    sitename: "",
    sitelibrary: "",
    apiversion: "",
    driveid: "",
    subsiteid: "",
    subsitename: "",
    appuserinfo: {},
    apiendpoints: [],
    appaccountuserid: "",
    noparent: false,
  };

  const [app, setApp] = useState(initialappstate);

  const initialerror = {
    appname: "",
    appenvironment: "",
    apprelationship: "",
    appautoapproval: "",
    //appaccountuserid:"",
  };
  const [errors, setErrors] = useState(initialerror);

  const initialisconnect = {
    appclientid: "",
    appclientsecret: "",
    appredirecturi: "",
    appaccountid: "",
    appdisplayname: "",
    appresthostname: "",
    appaccesstokenhostname: "",
  };
  const [errorsisconnect, setErrorsisconnect] = useState(initialisconnect);

  const initialendpoints = {
    endpointtype: "",
    endpointname: "",
    endpointvalue: "",
    endpointsecret: "",
    endpointminval: "",
  };
  const [errorsendpoint, setErrorsEndpoint] = useState(initialendpoints);

  const initialsharepoint = {
    tenantid: "",
    tenantname: "",
    // siteid: "",
    sitename: "",
    // subsiteid: "",
    // subsitename: "",
  };
  const [errorssharepoint, setErrorsSharepoint] = useState(initialsharepoint);

  let AutoApprovalList = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  //state variables

  //table functions
  const data = useMemo(() => [...list], [list]);
  const appColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "GroupID",
        accessor: "appuserinfo[0].groupid",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Source app name ",
        accessor: "appname",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Relationship ",
        accessor: "apprelationship",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "APP display name",

        sortable: true,
        width: "30%",
        Cell: ({ row }) => (
          <>
            {row?.original?.appuserinfo?.map((item) => (
              <span>{item.appdisplayname}</span>
            ))}
          </>
        ),
      },

      {
        Header: "Environment",
        accessor: "appenvironment",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Auto approval",
        accessor: "appautoapproval",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
        Cell: ({ value }) => {
          return (
            <span className="text-center">
              {value === "true" ? "Yes" : "No"}
            </span>
          );
        },
      },
      {
        Header: "Created on",
        accessor: "activity_history[0].activity_on",
        sortable: true,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => Moment(value).format(appConstants.DATEFORMAT),
      },
      {
        Header: "Active",
        accessor: "isactive",
        sortable: false,
        width: "10%",
        Filter: "",
        filter: "value",
        Cell: ({ value }) => {
          return (
            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
              <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
              {value === "1" ? "Yes" : "No"}
            </span>
          );
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          const onViewItemClick = () => {
            handleViewClickApps(row);
          };
          const onEditItemClick = () => {
            handleEditClickApps(row);
          };
          const onDeleteItemClick = () => {
            handleDeleteClickApps(row);
          };
          return (
            <div className="d-flex gap-4">
              <Button className="btn btn-sm " onClick={onViewItemClick}>
                View
              </Button>
              <Button className="btn btn-sm " onClick={onEditItemClick}>
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </Button>
              <Button className="btn btn-sm " onClick={onDeleteItemClick}>
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    [userinfo]
  );

  const hiddenappColumns = ["_id", "appuserinfo[0].groupid", "appautoapproval"];

  useEffect(() => {
    async function parseToken() {
      setLoading(true);
      if (isAuthenticated && userinfo) {
        loadLOBApps().then(async () => {
          // get_environment_config()
          await getAppListData();
          await parseInput();
        });
      }
      setLoading(false);
    }

    parseToken();
  }, []);

  useEffect(() => {
    if (isAuthenticated && userinfo) {
      getisconnectstatus(app.appname);
      getEndpointListLoad(app.appname);

      if (userinfo.action.toLowerCase() !== "add") {
        parseInput();
      }
    }
  }, [app.appname, app.appenvironment]);

  async function parseInput() {
    if (userinfo !== undefined) {
      switch (userinfo.action.toLowerCase()) {
        case "add":
          onAdd();
          break;
        case "edit":
          onEdit(userinfo.appid);
          break;
        case "view":
          onView(userinfo.appid);
          break;
        case "list":
          break;
        default:
          break;
      }
    }
  }

  const onAdd = () => {
    let env_type = devenv?.toLowerCase() === "prod" ? "Production" : "Sandbox";
    initialappstate.appenvironment = env_type;
    setApp(initialappstate);
    setAppuserinforow(initialappuserinfo);
    setApiendpointrow(initialapiendpoints);
    setIsAddApp(true);
  };
  const onEdit = (_id) => {
    console.log("userinfo", userinfo);
    console.log("devenv", devenv);
    getDataAppByID(_id);
    setIsEditApp(true);
  };
  const onView = (_id) => {
    setIsDetailApp(true);

    getDataAppByID(_id);
  };
  const onDelete = (_id) => {
    setIsDeleteApp(true);
    setRowToDelete(_id);
  };
  const handleAddClickApps = () => {
    onAdd();
  };

  const handleViewClickApps = (row) => {
    onView(row.original._id);
  };

  const handleEditClickApps = (row) => {
    onEdit(row.original._id);
  };
  const handleDeleteClickApps = (row) => {
    onDelete(row.original._id);
  };
  async function getAppListData() {
    if (userinfo.userid) {
      getapplistbyuserid(ctechapp.currentUser, userinfo).then((response) => {
        if (response !== undefined) {
          let api_settings_info = JSON.parse(response);
          console.log(api_settings_info);
          setList(api_settings_info);
          if (api_settings_info.length > 0) {
            let parent_app = api_settings_info.filter(
              (row) => row.apprelationship.toLowerCase() === "parent"
            );
            let msg =
              parent_app.length === 0 ? "Parent app is not configured." : "";
            setErrorMsg(msg);

            if (parent_app.length === 0) {
              setRelationshipList([
                { value: "Parent", label: "Parent" },
                { value: "Child", label: "Child" },
              ]);
            } else {
              setRelationshipList([{ value: "Child", label: "Child" }]);
            }
          }
        }
      });
    }
  }

  async function GetCommandpostAccountinfoByEmail() {
    return getCommandpostAccountinfoByEmail(
      ctechapp.currentUser,
      userinfo
    ).then((response) => {
      if (response !== undefined) {
        console.log("getCommandpostAccountinfoByEmail response", response);
        setcommandpostaccountinfo(response.result);
        return response.result;
      }
    });
  }
  function getDataAppByID(ids) {
    setLoading(false);

    getapplicationsbyid(ids, ctechapp.currentUser, userinfo).then(
      (response) => {
        if (response !== undefined) {
          console.log("response", response);
          let res_value = JSON.parse(response);
          let res = res_value[0];

          if (
            res.appname.toLowerCase() !== appmasterappname &&
            //res.appname.toLowerCase() !== "sharepoint" &&
            res.appname.toLowerCase() !== projectmasterappname &&
            res.appname.toLowerCase() !== contactmasterappname &&
            res.appname.toLowerCase() !== commandpostappname
          ) {
            setisConnect("true");
          }

          getisconnectstatus(res.appclientid);
          if (res.apiendpoints !== undefined) {
            setEndpointList(res.apiendpoints);
          }
          setAppuserinforow(res.appuserinfo[0]);
          // //load the relationship picklist
          if (res.apprelationship === "Parent" || res.noparent === true) {
            setRelationshipList([
              { value: "Parent", label: "Parent" },
              { value: "Child", label: "Child" },
            ]);
          } else {
            setRelationshipList([{ value: "Child", label: "Child" }]);
          }
        /*  let env_type =
            devenv?.toLowerCase() === "prod" ? "Production" : "Sandbox";
          if (res.appenvironment !== "Production")
            res.appenvironment = env_type;*/

          setApp(res);
          switch (res.appname.toLowerCase()) {
            case procoreappname: case salesforceappname: 
              {
              setshowSecuritySection(false);
              setshowTokenSection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(false);
              setshowEntraIDSection(false);
              setshowEndpointSection(false);
              if (devenv === "dev") {
                setshowTokenSection(true);
              }
              break;
            }
            case quickbookappname:
              setshowSecuritySection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(false);
              setshowEntraIDSection(false);
              setshowEndpointSection(false);
              if (devenv.toLowerCase() === "dev") {
                setshowTokenSection(true);
              } else {
                setshowTokenSection(false);
              }
              break;
            case commandpostappname:
              setshowSecuritySection(false);
              setshowTokenSection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(false);
              setshowEntraIDSection(false);
              if (devenv.toLowerCase() === "dev") {
                setshowAccountSection(true);
                setshowEndpointSection(true);
              } else {
                setshowEndpointSection(false);
              }
              break;
            case projectmasterappname:
              setshowSecuritySection(false);
              setshowTokenSection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(false);
              setshowEntraIDSection(false);
              setshowEndpointSection(false);
              break;
            case contactmasterappname:
              setshowSecuritySection(false);
              setshowTokenSection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(false);
              setshowEntraIDSection(false);
              setshowEndpointSection(false);
              break;
            case sharepointappname:
              setshowEndpointSection(false);
              setshowSharepointSection(true);
              setshowMicrosoftTenantSection(true);
              setshowEntraIDSection(false);
              if (devenv === "dev") {
                setshowTokenSection(true);
                setshowSecuritySection(true);
              } else {
                setshowTokenSection(false);
                setshowSecuritySection(false);
              }
              break;
            case entraidappname:
              setshowEndpointSection(false);
              setshowSharepointSection(false);
              setshowMicrosoftTenantSection(true);
              setshowEntraIDSection(true);
              if (devenv === "dev") {
                setshowTokenSection(true);
                setshowSecuritySection(true);
              } else {
                setshowTokenSection(false);
                setshowSecuritySection(false);
              }
              break;
            default:
              console.log("input case not matching value : ", res.appname);
          }
        }
      }
    );
  }

  function ondelete(id) {
    try {
      deleteAppByuserID(id, ctechapp.currentUser, userinfo).then((response) => {
        if (response !== undefined) {
          setIsDeleted(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleConfirmDelete = () => {
    setIsDeleteApp(false);
    ondelete(rowToDelete);
  };
  const handleConfirmDeleted = (row) => {
    setIsDeleted(false);
    getAppListData(userinfo);
    goToHomePage();
  };
  const handleCancelDelete = (row) => {
    setIsDeleteApp(false);
  };

  const handleDropDownChange = (selectedOption, updatedata) => {
    const { name, value } = selectedOption.target;
    if (updatedata === "endpoint") {
      setApiendpointrow({ ...apiendpointrow, [name]: value.trim() });
      setErrorsEndpoint({ ...errorsendpoint, [name]: "" });
    } else {
      setApp({ ...app, [name]: value.trim() });
      setErrors({ ...errors, [name]: "" });
      setErrorsisconnect({ ...errorsisconnect, [name]: "" });
    }

    if (name === "appname") {
      console.log("userinfo.firstName ", userinfo?.firstName);
      let _appdisplayname =
        userinfo.firstName.charAt(0) + userinfo?.lastName.charAt(0);

      _appdisplayname =
        _appdisplayname?.length > 0
          ? _appdisplayname + "_" + value.trim()
          : value.trim();

      setAppuserinforow({
        ...appuserinforow,
        ["appdisplayname"]: _appdisplayname,
      });
    }
  };

  const handleAddDropDownChange = (selectedOption, updatedata) => {
    const { name, value } = selectedOption.target;

    let _appdata = { ...app };

    _appdata[name] = value.trim();

    setErrors({ ...errors, [name]: "" });
    setErrorsisconnect({ ...errorsisconnect, [name]: "" });

    if (name === "appname") {
      console.log("userinfo.firstName ", userinfo?.firstName);
      let _appdisplayname =
        userinfo.firstName?.charAt(0) + userinfo?.lastName?.charAt(0);

      _appdisplayname =
        _appdisplayname?.length > 0
          ? _appdisplayname + "_" + value.trim()
          : value.trim();

      setAppuserinforow({
        ...appuserinforow,
        ["appdisplayname"]: _appdisplayname,
      });
      getisconnectstatus(value);
    }

    setApp(_appdata);

    switch (value.toLowerCase()) {
      case procoreappname: case salesforceappname:
        {
        setshowAccountSection(true);
        setshowSecuritySection(false);
        setshowTokenSection(false);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(false);
        setshowEntraIDSection(false);
        if (devenv === "dev") {
          setshowTokenSection(true);
          setshowSecuritySection(true);
          setshowTokenSection(true);
        }
        break;
      }
      case quickbookappname:
        setshowAccountSection(true);
        setshowSecuritySection(false);
        setshowTokenSection(false);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(false);
        setshowEntraIDSection(false);
        if (devenv === "dev") {
          setshowTokenSection(true);
          setshowSecuritySection(true);
          setshowTokenSection(true);
        }
        break;
      case commandpostappname:
        setshowAccountSection(true);
        setshowSecuritySection(false);
        setshowTokenSection(false);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(false);
        if (devenv === "dev") {
          setshowEndpointSection(true);
          setshowSecuritySection(true);
        }

        break;
      case sharepointappname:
        setshowAccountSection(true);
        setshowMicrosoftTenantSection(true);
        setshowEndpointSection(false);
        setshowSharepointSection(true);
        setshowEntraIDSection(false);
        if (devenv === "dev") {
          setshowTokenSection(true);
          setshowSecuritySection(true);
        } else {
          setshowTokenSection(false);
          setshowSecuritySection(false);
        }

        break;
      case entraidappname:
        setshowAccountSection(true);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(true);
        setshowEntraIDSection(true);
        if (devenv === "dev") {
          setshowTokenSection(true);
          setshowSecuritySection(true);
        } else {
          setshowTokenSection(false);
          setshowSecuritySection(false);
        }

        break;
      case projectmasterappname:
        setshowAccountSection(true);
        setshowSecuritySection(false);
        setshowTokenSection(false);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(false);
        setshowEntraIDSection(false);

        break;
      case contactmasterappname:
        setshowAccountSection(true);
        setshowSecuritySection(false);
        setshowTokenSection(false);
        setshowEndpointSection(false);
        setshowSharepointSection(false);
        setshowMicrosoftTenantSection(false);
        setshowEntraIDSection(false);

        break;
      default:
        console.log("input case not matching value : ", value);
    }
  };

  const getisconnectstatus = (appname) => {
    const api_info = lobapps.filter((record) => record.appname === appname);

    if (api_info.length > 0) {
      setisConnect(api_info[0].isconnect);
      setisEndpoint(api_info[0].isendpoint);
    }
  };

  const getEndpointListLoad = async (appname) => {
    let _appdata = structuredClone(app);
    if (appname.toLowerCase() === commandpostappname) {
      let _commandpostaccountinfo = await GetCommandpostAccountinfoByEmail();
      await GetEndpointTypeLookup();
      if (_commandpostaccountinfo?.success === 1) {
        _appdata.appname = appname;
        _appdata.appaccountuserid = "_User$" + _commandpostaccountinfo?.userId;
        _appdata.appaccountid =
          "accountSettings$" + _commandpostaccountinfo?.accountId;
        // setApp(_app);
      } else {
        setiscommandpostaccountinfo(true);
      }
      getAppsCollectionListsByFormName(
        "EndpointList",
        ctechapp.currentUser
      ).then((response) => {
        if (response !== undefined) {
          var _endpointlist = JSON.parse(response);
          var endointdataList = [];
          if (_endpointlist.length > 0) {
            _endpointlist.map((endpointinfo) => {
              var _apiendpointrow = {};
              _apiendpointrow.id = uuidv4();
              _apiendpointrow.userid = userinfo.userid;
              _apiendpointrow.endpointtype = endpointinfo.listValue;
              _apiendpointrow.endpointname = endpointinfo.endpointName;
              _apiendpointrow.endpointvalue = endpointinfo.endpointValue;
              _apiendpointrow.endpointsecret = endpointinfo.endpointSecret;
              _apiendpointrow.endpointminval = "";
              endointdataList.push(_apiendpointrow);
            });
            console.log("endointdataList", endointdataList);
            setEndpointList(endointdataList);
          }
        }
      });
      //console.log("endointdataList ", endointList);
    } else {
      setEndpointList([]);
      _appdata.appname = appname;
      _appdata.appaccountuserid = "";
      _appdata.appaccountid = "";
      // setApp(_app);
    }

    // console.log("_appdata", JSON.stringify(_appdata));

    let api_info = lobapps.filter(
      (record) =>
        record.appname?.toLowerCase() === _appdata.appname?.toLowerCase() &&
        record.appenv?.toLowerCase() === _appdata.appenvironment?.toLowerCase()
    );
    if (!_appdata._id) {
      if (api_info.length > 0) {
        console.log("api_info", api_info);
        _appdata.appresthostname = api_info[0].apphostname;
        _appdata.appaccesstokenhostname = api_info[0].apptokenhostname;
        _appdata.appredirecturi = api_info[0].appredirecturl;
        _appdata.appclientid = api_info[0].appclientid;
        _appdata.appclientsecret = api_info[0].appclientsecret;
        _appdata.appurl = api_info[0].appurl;
        _appdata.apiversion = api_info[0].apiversion;

        if (
          api_info[0].appname.toLowerCase() === sharepointappname ||
          api_info[0].appname.toLowerCase() === entraidappname
        ) {
          _appdata.appaccountid =
            api_info[0].appname.toLowerCase() !== entraidappname
              ? userinfo.userid
              : api_info[0].tenantid;
          _appdata.tenantid = api_info[0].tenantid;
          _appdata.tenantname = api_info[0].tenantname;
          _appdata.siteid = api_info[0].siteid;
          _appdata.sitename = api_info[0].sitename;
          _appdata.sitelibrary = api_info[0].sitelibrary;
        } else {
          _appdata.tenantid = "";
          _appdata.tenantname = "";
          _appdata.siteid = "";
          _appdata.sitename = "";
          _appdata.sitelibrary = "";
        }
      } else {
        _appdata.appresthostname = "";
        _appdata.appaccesstokenhostname = "";
        _appdata.appredirecturi = "";
        _appdata.appclientid = "";
        _appdata.appclientsecret = "";
        _appdata.tenantid = "";
        _appdata.tenantname = "";
        _appdata.siteid = "";
        _appdata.sitename = "";
        _appdata.sitelibrary = "";
        _appdata.apiversion = "";
      }
    }
    setApp(_appdata);
  };

  async function loadLOBApps() {
    try {
      getLOBApps(ctechapp.currentUser, userinfo).then((response) => {
        let lob_info = JSON.parse(response);
        if (response !== undefined || lob_info.length !== 0) {
          setLOBApps(lob_info);

          let _applist = [...new Set(lob_info.map((x) => x.appname))];
          let options = _applist.map(function (row) {
            return { value: row, label: row };
          });
          setAppList(options);

          let _appenvlist = [...new Set(lob_info.map((x) => x.appenv))];
          let options_env = _appenvlist.map(function (row) {
            return { value: row, label: row };
          });
          setEnvironmentList(options_env);
        }
      });
    } catch (exception) {
      setLoading(false);
      console.log(exception);
    }
  }

  async function GetEndpointTypeLookup() {
    await getAppsCollectionListsByFormName(
      "EndpointList",
      ctechapp.currentUser
    ).then((response) => {
      if (response !== undefined) {
        let endpointtype_ddl_info = JSON.parse(response);

        let options = endpointtype_ddl_info.map(function (endpointtype_ddl) {
          return {
            value: endpointtype_ddl.listValue,
            label: endpointtype_ddl.listName,
          };
        });
        setEndpointtypelookuplist(options);
      }
    });
  }

  const handleFieldEndpointRequiredValidation = () => {
    console.log("apiendpointrow", apiendpointrow);
    let iserror = "";
    setErrorsEndpoint({
      ...errorsendpoint,
      endpointtype:
        apiendpointrow.endpointtype === ""
          ? (iserror = "Endpoint type required")
          : errorsendpoint.endpointtype,
      endpointname:
        apiendpointrow.endpointname === ""
          ? (iserror = "Endpoint name required")
          : errorsendpoint.endpointname,
      endpointvalue:
        apiendpointrow.endpointvalue === ""
          ? (iserror = "Endpoint value required")
          : errorsendpoint.endpointvalue,
      endpointsecret:
        apiendpointrow.endpointsecret === ""
          ? (iserror = "Endpoint secret required")
          : errorsendpoint.endpointsecret,
    });
    // console.log(iserror);
    return iserror.length === 0;
  };

  const addendpointinfo = async () => {
    if (EndpointvalidateForm()) {
      setEndpointlistmandatory("");

      if (endpointlist !== null) {
        const objIndex = endpointlist.findIndex(
          (obj) => obj.endpointtype === apiendpointrow.endpointtype
        );
        if (objIndex !== -1) {
          const rowsInput = [...endpointlist];
          rowsInput[objIndex] = apiendpointrow;
          setEndpointList(rowsInput);
          setApiendpointrow(initialapiendpoints);
        } else {
          setEndpointList([...endpointlist, apiendpointrow]);
          setApiendpointrow(initialapiendpoints);
        }
      } else {
        let _endpointlist = [];
        _endpointlist.push(apiendpointrow);
        setEndpointList(_endpointlist);
        setApiendpointrow(initialapiendpoints);
      }
      setErrorsEndpoint({
        ...errorsendpoint,
        ["endpointminval"]: "",
      });
    }
  };
  const clearenvironmentinfo = async (e) => {
    e.preventDefault();
    setApiendpointrow(initialapiendpoints);
  };

  const goToHomePage = () => {
    setIsAppIsCreated(false);
    setIsAppIsUpdated(false);
    setErrors(initialerror);
    setErrorsisconnect(initialisconnect);
    setErrorsEndpoint(initialendpoints);
    setErrorsSharepoint(initialsharepoint);
    setIsEditApp(false);
    setIsAddApp(false);
    setApp(initialappstate);
    PopupClose();
  };
  async function PopupClose() {
    //e.preventDefault();
    try {
      window.close();
      if (window.opener && !window.opener.closed) {
        window.opener.location.reload();
      }
    } catch (err) {
      console.log("PopupClose : " + err);
    }
  }
  const handleSucessSweetAlertForConnect = () => {
    setisSuccessForConnect(false);
    authorizeUri();
  };

  const handleSucessSweetAlertForSave = () => {
    setisSuccessForSave(false);
    btnSave();
  };

  const btnAddSave = async () => {
    if (validateForm()) {
      let _appinfo = { ...app };

      const isvalid = await handleDuplicationValidation(_appinfo);

      if (!isvalid) {
        SetValidatemsg(
          "Another user in your company has already added this connection"
        );
        setisShowDuplicatevalidation(true);
        return;
      }

      if (appuserinforow.id === "") {
        appuserinforow.id = uuidv4();
      }
      appuserinforow.id === ""
        ? (appuserinforow.status = "created")
        : (appuserinforow.status = "linked");
      _appinfo.appuserinfo = appuserinforow;

      if (endpointlist.length > 0) {
        _appinfo.apiendpoints = endpointlist;
      }

      const data_result =
        await ctechapp.currentUser.functions.insertApplication(
          _appinfo,
          userinfo
        );
      if (data_result !== null || data_result !== undefined) {
        console.log("data_result", data_result);
        setIsAppIsCreated(true);
      }
    }
  };

  const btnSave = async () => {
    try {
      if (validateForm()) {
        let _appinfo = { ...app };
        const isvalid = await handleDuplicationValidation(_appinfo);
        if (!isvalid) {
          SetValidatemsg(
            "Another user in your company has already added this connection"
          );
          setisShowDuplicatevalidation(true);
          return;
        }
        if (appuserinforow.id === "") {
          appuserinforow.id = uuidv4();
        }
        appuserinforow.id === ""
          ? (appuserinforow.status = "created")
          : (appuserinforow.status = "linked");
        _appinfo.appuserinfo = appuserinforow;

        if (endpointlist.length > 0) {
          _appinfo.apiendpoints = endpointlist;
        }

        const data_result =
          await ctechapp.currentUser.functions.updateApplicationByID(
            _appinfo,
            userinfo
          );
        if (data_result !== null || data_result !== undefined) {
          console.log("data_result", data_result);
          setIsAppIsUpdated(true);
          //  window.close();
        }
      }
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  };

  const EndpointvalidateForm = () => {
    try {
      let valid = false;
      valid = handleFieldEndpointRequiredValidation();
      Object.values(errorsendpoint).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      console.log("errorsendpoint", errorsendpoint);
      console.log("EndpointvalidateForm : ", valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const validateForm = () => {
    try {
      let valid = false;
      valid = handleFieldRequiredValidation();
      Object.values(errors).forEach((val) => {
        if (val.length > 0) valid = false;
      });
      // console.log(valid);
      return valid;
    } catch (error) {
      return false;
    }
  };
  const handleFieldRequiredValidation = () => {
    let iserror = "";

    // Auto mandatory field validation
    var errors1 = { ...errors };
    var errors1 = { ...errors };

    setErrors({
      ...errors,
      appname:
        app.appname === ""
          ? (iserror = "Source app name required")
          : errors.appname,
      appenvironment:
        app.appenvironment === ""
          ? (iserror = "Environment required")
          : errors.appenvironment,
      apprelationship:
        app.apprelationship === ""
          ? (iserror = "Relationship required")
          : errors.apprelationship,
      appautoapproval:
        app.appautoapproval === ""
          ? (iserror = "Auto approval required")
          : errors.appautoapproval,
      // appaccountuserid:
      //   app.appaccountuserid === ""
      //     ? (iserror = " Account user id  required")
      //     : errors.appaccountuserid,
    });

    // sharepoint section field validation
    if (showMicrosoftTenantSection === true) {
      console.log("app.appname.toLowerCase()", app.appname.toLowerCase());
      setErrorsSharepoint({
        ...errorssharepoint,

        tenantid:
          (app.appname.toLowerCase() === entraidappname ||
            app.appname.toLowerCase() === sharepointappname) &&
            app.tenantid === ""
            ? (iserror = "Tenant id required")
            : errorssharepoint.tenantid,
        tenantname:
          (app.appname.toLowerCase() === entraidappname ||
            app.appname.toLowerCase() === sharepointappname) &&
            app.tenantname === ""
            ? (iserror = "Tenant name required")
            : errorssharepoint.tenantname,
        // siteid:
        //   app.siteid === ""
        //     ? (iserror = "Site ID required")
        //     : errorssharepoint.siteid,
        sitename:
          app.appname.toLowerCase() !== entraidappname && app.sitename === ""
            ? (iserror = "Site name required")
            : errorssharepoint.sitename,
        // subsiteid:
        //   app.subsiteid === ""
        //     ? (iserror = "Subsite ID required")
        //     : errorssharepoint.subsiteid,
        // subsitename:
        //   app.subsitename === ""
        //     ? (iserror = "Subsite name required")
        //     : errorssharepoint.subsitename,
      });
    }

    // secutiry and token field validation
    if (isconnect === "true") {
      setErrorsisconnect({
        ...errorsisconnect,

        appclientid:
          app.appclientid === ""
            ? (iserror = "Client id required")
            : errorsisconnect.appclientid,
        appclientsecret:
          app.appname.toLowerCase() !== sharepointappname &&
            app.appclientsecret === ""
            ? (iserror = "Client secret required")
            : errorsisconnect.appclientsecret,
        appredirecturi:
          app.appredirecturi === ""
            ? (iserror = "Redirect url required")
            : errorsisconnect.appredirecturi,
        appaccountid:
          app.appaccountid === ""
            ? (iserror = "Account id required")
            : errorsisconnect.appaccountid,
        appdisplayname:
          appuserinforow.appdisplayname === ""
            ? (iserror = "Account displayname required")
            : errorsisconnect.appdisplayname,
        appresthostname:
          app.appresthostname === ""
            ? (iserror = "App hostname required")
            : errorsisconnect.appresthostname,
        appaccesstokenhostname:
          app.appaccesstokenhostname === ""
            ? (iserror = "App token hostname required")
            : errorsisconnect.appaccesstokenhostname,
      });
    }

    if (isendpoint === "true") {
      setErrorsEndpoint({
        ...errorsendpoint,
        endpointminval:
          endpointlist.length === 0
            ? (iserror = "Atleast one list value required")
            : errorsendpoint.endpointminval,
      });
    }

    // console.log(iserror);
    return iserror.length === 0;
  };

  const handleDuplicationValidation = async (_appinfo) => {
    const data_result = await ctechapp.currentUser.functions.isValidApplication(
      _appinfo,
      userinfo
    );
    console.log("data_result : ",JSON.stringify(data_result));
    return data_result;
  };

  function authorizeUriTrue() {
    try {
      authorizeUri();
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  }
  const handleSweetAlertValidationMessage = () => {
    setisShowDuplicatevalidation(false);
    setisShowError(false);
    SetValidatemsg("");
  };
  const handlecommandpostaccountinfo = () => {
    setiscommandpostaccountinfo(false);
    PopupClose();
  };
  function btnAddSaveTrue() {
    try {
      btnAddSave();
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  }
  function btnSaveTrue() {
    try {
      btnSave();
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  }

  const authorizeUri = async (authUri) => {
    if (validateForm()) {
      let _appinfo = { ...app };
      const isvalid = await handleDuplicationValidation(_appinfo);
      if (!isvalid) {
        SetValidatemsg(
          "Another user in your company has already added this connection "
        );
        setisShowDuplicatevalidation(true);
        return;
      }
      delete _appinfo?.activity_history;

      if (appuserinforow.id === "") {
        appuserinforow.id = uuidv4();
      }
      appuserinforow.id === ""
        ? (appuserinforow.status = "created")
        : (appuserinforow.status = "linked");
      _appinfo.appuserinfo = appuserinforow;

      if (endpointlist.length > 0) {
        _appinfo.apiendpoints = endpointlist;
      }

      const data_result =
        await ctechapp.currentUser.functions.updateApplicationByID(
          _appinfo,
          userinfo
        );

      console.log("app", _appinfo);
      var state_var = "";
      let connectapp = "";
      if (app.appname.toLowerCase() === procoreappname || salesforceappname) {
        state_var = {
          //appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: userinfo.userid.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname?.trim(),
          apiappcompanyid: app.appaccountid?.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        var jsonstate = JSON.stringify(state_var);
        authUri =
          app.appaccesstokenhostname.trim() +
          `/authorize?response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
        connectapp =
          app.appname.toLowerCase() === procoreappname
            ? "procoreconnect"
            : "salesforceconnect";
      }

      if (app.appname.toLowerCase() === quickbookappname) {
        connectapp = "qbconnect";
        state_var = {
          // appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: userinfo.userid.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname.trim(),
          apiappcompanyid: app.appaccountid.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        jsonstate = JSON.stringify(state_var);
        var oauthClient = new OAuthClient({
          clientId: app.appclientid.trim(), // enter the apps `clientId`
          clientSecret: app.appclientsecret.trim(), // enter the apps `clientSecret`
          environment: app.appenvironment.trim(), // enter either `sandbox` or `production`
          redirectUri: app.appredirecturi.trim(), // enter the redirectUri
          logging: false, // by default the value is `false`
        });
        authUri = oauthClient.authorizeUri({
          scope: [OAuthClient.scopes.Accounting],
          state: jsonstate,
        });
        //authUri = `https://appcenter.intuit.com/connect/oauth2?response_type=code&client_id=${apiclientid}&client_secret=${apiclientsecret}&redirect_uri=${apiredirecturi}&scope=${iscope}&state=${jsonstate}`
      }
      if (app.appname.toLowerCase() === sharepointappname) {
        state_var = {
          //appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: userinfo.userid.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname.trim(),
          apiappcompanyid: app.appaccountid.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        jsonstate = JSON.stringify(state_var);

        let _appaccesstokenhostname = app.appaccesstokenhostname.trim();
        _appaccesstokenhostname = _appaccesstokenhostname.replace(
          "common",
          app.tenantid.trim()
        );

        var scope =
          "openid%20offline_access%20User.Read%20Sites.FullControl.All";
        authUri =
          _appaccesstokenhostname.trim() +
          `/authorize?scope=${scope}&response_mode=query&response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
        connectapp = "sharepointconnect";
      }
      if (app.appname.toLowerCase() === entraidappname) {
        state_var = {
          //appinfo: _appinfo,
          _id: data_result,
          clientid: app.appclientid.trim(),
          clientsecret: app.appclientsecret.trim(),
          redirecturi: app.appredirecturi.trim(),
          userid: userinfo.userid.trim(),
          emailaddress: userinfo.email.trim(),
          environment: app.appenvironment.trim(),
          granttype: "",
          accesstokenhostname: app.appaccesstokenhostname.trim(),
          apihostname: app.appresthostname.trim(),
          apiappcompanyid: app.tenantid.trim(),
          apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
          settingsid: data_result,
          callbackpage: "editapps",
          appname: app.appname.trim(),
        };
        jsonstate = JSON.stringify(state_var);
        let _appaccesstokenhostname = app.appaccesstokenhostname.trim();
        _appaccesstokenhostname = _appaccesstokenhostname.replace(
          "common",
          app.tenantid.trim()
        );

        scope =
          "openid%20offline_access%20User.Read%20Domain.Read.All%20User.ReadWrite.All";
        authUri =
          _appaccesstokenhostname.trim() +
          `/authorize?scope=${scope}&response_mode=query&response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
        connectapp = "entraidconnect";
      }
      // else {
      //   if (state_var === "") {
      //     authUri =
      //       app.appaccesstokenhostname.trim() +
      //       `/authorize?response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
      //     connectapp = "procoreconnect";
      //   }
      // }
      var width = 450,
        height = 730,
        left = window.screen.width / 2 - width / 2,
        top = window.screen.height / 2 - height / 2;
      // Launch Popup using the JS window Object
      var parameters =
        "location=1, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left;
      var win = window.open(authUri, connectapp, parameters);
      var pollOAuth = window.setInterval(function () {
        try {
          if (win.document.URL.indexOf("code") !== -1) {
            window.clearInterval(pollOAuth);
            //  win.close();
          }
        } catch (e) { }
      }, 1000);
    }
  };
  function addauthorizeUriTrue() {
    try {
      addauthorizeUri();
    } catch (e) {
      setisShowError(true);
      if (e.error !== undefined && e.error !== "") SetValidatemsg(e.error);
      else SetValidatemsg(e);
    }
  }
  const addauthorizeUri = async (authUri) => {
    try {
      if (validateForm()) {
        let _appinfo = { ...app };
        const isvalid = await handleDuplicationValidation(_appinfo);
        if (!isvalid) {
          SetValidatemsg(
            "Another user in your company has already added this connection "
          );
          setisShowDuplicatevalidation(true);
          return;
        }
        delete _appinfo?.activity_history;

        if (appuserinforow.id === "") {
          appuserinforow.id = uuidv4();
        }
        appuserinforow.id === ""
          ? (appuserinforow.status = "created")
          : (appuserinforow.status = "linked");
        _appinfo.appuserinfo = appuserinforow;

        if (endpointlist.length > 0) {
          _appinfo.apiendpoints = endpointlist;
        }

        const data_result =
          await ctechapp.currentUser.functions.insertApplication(
            _appinfo,
            userinfo
          );

        console.log("app", _appinfo);
        console.log("data_result", data_result);
        var state_var = "";
        let connectapp = "";
        if (app.appname === procoreappname || salesforceappname) {
          state_var = {
            //appinfo: _appinfo,
            _id: data_result,
            clientid: app.appclientid.trim(),
            clientsecret: app.appclientsecret.trim(),
            redirecturi: app.appredirecturi.trim(),
            userid: userinfo.userid.trim(),
            emailaddress: userinfo.email.trim(),
            environment: app.appenvironment.trim(),
            granttype: "",
            accesstokenhostname: app.appaccesstokenhostname.trim(),
            apihostname: app.appresthostname.trim(),
            apiappcompanyid: app.appaccountid.trim(),
            apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
            settingsid: data_result,
            callbackpage: "addapps",
            appname: app.appname.trim(),
          };
          var jsonstate = JSON.stringify(state_var);

          authUri =
            app.appaccesstokenhostname.trim() +
            `/authorize?response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
          connectapp =
            app.appname.toLowerCase() === procoreappname
              ? "procoreconnect"
              : "salesforceconnect";
        }

        if (app.appname.toLowerCase() === quickbookappname) {
          connectapp = "qbconnect";
          state_var = {
            // appinfo: _appinfo,
            _id: data_result,
            clientid: app.appclientid.trim(),
            clientsecret: app.appclientsecret.trim(),
            redirecturi: app.appredirecturi.trim(),
            userid: userinfo.userid.trim(),
            emailaddress: userinfo.email.trim(),
            environment: app.appenvironment.trim(),
            granttype: "",
            accesstokenhostname: app.appaccesstokenhostname.trim(),
            apihostname: app.appresthostname.trim(),
            apiappcompanyid: app.appaccountid.trim(),
            apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
            settingsid: data_result,
            callbackpage: "addapps",
            appname: app.appname.trim(),
          };
          jsonstate = JSON.stringify(state_var);
          var oauthClient = new OAuthClient({
            clientId: app.appclientid.trim(), // enter the apps `clientId`
            clientSecret: app.appclientsecret.trim(), // enter the apps `clientSecret`
            environment: app.appenvironment.trim(), // enter either `sandbox` or `production`
            redirectUri: app.appredirecturi.trim(), // enter the redirectUri
            logging: false, // by default the value is `false`
          });
          authUri = oauthClient.authorizeUri({
            scope: [OAuthClient.scopes.Accounting],
            state: jsonstate,
          });
        }
        if (app.appname.toLowerCase() === sharepointappname) {
          state_var = {
            //appinfo: _appinfo,
            _id: data_result,
            clientid: app.appclientid.trim(),
            clientsecret: app.appclientsecret.trim(),
            redirecturi: app.appredirecturi.trim(),
            userid: userinfo.userid.trim(),
            emailaddress: userinfo.email.trim(),
            environment: app.appenvironment.trim(),
            granttype: "",
            accesstokenhostname: app.appaccesstokenhostname.trim(),
            apihostname: app.appresthostname.trim(),
            apiappcompanyid: app.appaccountid.trim(),
            apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
            settingsid: data_result,
            callbackpage: "addapps",
            appname: app.appname.trim(),
          };
          jsonstate = JSON.stringify(state_var);
          var scope =
            "openid%20offline_access%20User.Read%20Sites.FullControl.All";

          let _appaccesstokenhostname = app.appaccesstokenhostname.trim();
          _appaccesstokenhostname = _appaccesstokenhostname.replace(
            "common",
            app.tenantid.trim()
          );

          authUri =
            _appaccesstokenhostname.trim() +
            `/authorize?scope=${scope}&response_mode=query&response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
          connectapp = "sharepointconnect";
        }

        if (app.appname.toLowerCase() === entraidappname) {
          state_var = {
            //appinfo: _appinfo,
            _id: data_result,
            clientid: app.appclientid.trim(),
            clientsecret: app.appclientsecret.trim(),
            redirecturi: app.appredirecturi.trim(),
            userid: userinfo.userid.trim(),
            emailaddress: userinfo.email.trim(),
            environment: app.appenvironment.trim(),
            granttype: "",
            accesstokenhostname: app.appaccesstokenhostname.trim(),
            apihostname: app.appresthostname.trim(),
            apiappcompanyid: app.tenantid.trim(),
            apiappcompanydisplayname: appuserinforow.appdisplayname.trim(),
            settingsid: data_result,
            callbackpage: "addapps",
            appname: app.appname.trim(),
          };
          jsonstate = JSON.stringify(state_var);

          let _appaccesstokenhostname = app.appaccesstokenhostname.trim();
          _appaccesstokenhostname = _appaccesstokenhostname.replace(
            "common",
            app.tenantid.trim()
          );

          scope =
            "openid%20offline_access%20User.Read%20Domain.Read.All%20User.ReadWrite.All";
          authUri =
            _appaccesstokenhostname.trim() +
            `/authorize?scope=${scope}&response_mode=query&response_type=code&client_id=${app.appclientid.trim()}&state=${jsonstate}&redirect_uri=${app.appredirecturi.trim()}`;
          connectapp = "entraidconnect";
        }

        var width = 450,
          height = 730,
          left = window.screen.width / 2 - width / 2,
          top = window.screen.height / 2 - height / 2;
        // Launch Popup using the JS window Object
        var parameters =
          "location=1, width=" +
          width +
          ", height=" +
          height +
          ", top=" +
          top +
          ", left=" +
          left;
        var win = window.open(authUri, connectapp, parameters);
        var pollOAuth = window.setInterval(function () {
          try {
            if (win.document.URL.indexOf("code") !== -1) {
              window.clearInterval(pollOAuth);
              // win.close();
            }
          } catch (e) { }
        }, 1000);
      }
    } catch (e) {
      setisShowError(true);
      SetValidatemsg(e.error);
    }
  };

  const handleChange = (event, updatedata) => {
    SetValidatemsg("");

    if (updatedata === "endpoint") {
      setApiendpointrow({
        ...apiendpointrow,
        [event.target.name]: event.target.value.trim(),
      });
      setErrorsEndpoint({
        ...errorsendpoint,
        [event.target.name]: "",
      });
    } else if (updatedata === "appuser") {
      setAppuserinforow({
        ...appuserinforow,
        [event.target.name]: event.target.value.trim(),
      });

      setErrorsisconnect({ ...errorsisconnect, [event.target.name]: "" });
    } else {
      const path = event.target.name.split(".");
      const finalProp = path.pop();
      let newuser = { ...app };
      let pointer = newuser;
      path.forEach((el) => {
        pointer[el] = { ...pointer[el] };
        pointer = pointer[el];
      });
      pointer[finalProp] =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value.trim();

      if (event.target.name === "tenantid") {
        newuser.appaccountid = event.target.value.trim();
      }

      setApp(newuser);
      setErrors({ ...errors, [event.target.name]: "" });
      setErrorsisconnect({ ...errorsisconnect, [event.target.name]: "" });
    }
  };

  const adddata = useMemo(() => [...endpointlist], [endpointlist]);
  const addappColumns = useMemo(
    () => [
      {
        Header: "userid",
        accessor: "userid",
        sortable: false,
        width: "20%",
      },

      {
        Header: "Endpoint type ",
        accessor: "endpointtype",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint name",
        accessor: "endpointname",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      // {
      //   Header: "Endpoint Value",
      //   accessor: "endpointvalue",
      //   sortable: true,
      //   width: "30%",
      //   Filter: "",
      //   filter: "",
      // },
      // {
      //   Header: "Endpoint Secret",
      //   accessor: "endpointsecret",
      //   sortable: true,
      //   width: "30%",
      //   Filter: "",
      //   filter: "",
      // },
      {
        Header: "Action",
        Filter: "",
        filter: "",
        Cell: (rowdata) => {
          const onEditItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              setApiendpointrow(rowdata.row.original);
            }
          };
          const onDeleteItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              dataCopy.splice(rowdata.row.index, 1);
              setEndpointList(dataCopy);
            }
          };
          return (
            <div className="d-flex gap-4">
              <span
                className="btn btn-lg btn-icon btn-circle btn-primary "
                onClick={onEditItemClick}
              >
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </span>
              <span
                className="btn btn-lg btn-icon btn-circle btn-danger"
                onClick={onDeleteItemClick}
              >
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    [userinfo]
  );

  const hiddenaddappColumns = ["userid"];
  const editdata = useMemo(() => [...endpointlist], [endpointlist]);
  const editappColumns = useMemo(
    () => [
      {
        Header: "userid",
        accessor: "userid",
        sortable: false,
        width: "20%",
      },

      {
        Header: "Endpoint type ",
        accessor: "endpointtype",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint name",
        accessor: "endpointname",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      // {
      //   Header: "Endpoint Value",
      //   accessor: "endpointvalue",
      //   sortable: true,
      //   width: "30%",
      //   Filter: "",
      //   filter: "",
      // },
      // {
      //   Header: "Endpoint Secret",
      //   accessor: "endpointsecret",
      //   sortable: true,
      //   width: "30%",
      //   Filter: "",
      //   filter: "",
      // },
      {
        Header: "Action",
        Filter: "",
        filter: "",
        Cell: (rowdata) => {
          const onEditItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              setApiendpointrow(rowdata.row.original);
            }
          };
          const onDeleteItemClick = () => {
            const dataCopy = rowdata.data;
            if (dataCopy.length > 0) {
              dataCopy.splice(rowdata.row.index, 1);
              setEndpointList(dataCopy);
            }
          };
          return (
            <div className="d-flex gap-4">
              <span
                className="btn btn-lg btn-icon btn-circle btn-primary "
                onClick={onEditItemClick}
              >
                {/* <i className="fas fa-2x  fa-pencil"></i> */}
                Edit
              </span>
              <span
                className="btn btn-lg btn-icon btn-circle btn-danger"
                onClick={onDeleteItemClick}
              >
                {/* <i className="fas fa-2x  fa-trash-can"></i> */}
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    [userinfo]
  );
  const hiddeneditappColumns = ["userid"];
  const detaildata = useMemo(() => [...endpointlist], [endpointlist]);
  const detailappColumns = useMemo(
    () => [
      {
        Header: "userid",
        accessor: "userid",
        sortable: false,
        width: "20%",
      },

      {
        Header: "Endpoint type ",
        accessor: "endpointtype",
        sortable: true,
        width: "20%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint name",
        accessor: "endpointname",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint value",
        accessor: "endpointvalue",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      {
        Header: "Endpoint secret",
        accessor: "endpointsecret",
        sortable: true,
        width: "30%",
        Filter: "",
        filter: "",
      },
      // {
      //   Header: "Action",
      //   Filter: "",
      //   filter: "",
      //   Cell: (rowdata) => {
      //     const onEditItemClick = () => {
      //       const dataCopy = rowdata.data;
      //       if (dataCopy.length > 0) {
      //         setApiendpointrow(rowdata.row.original);
      //       }
      //     };
      //     const onDeleteItemClick = () => {
      //       const dataCopy = rowdata.data;
      //       if (dataCopy.length > 0) {
      //         dataCopy.splice(rowdata.row.index, 1);
      //         setEndpointList(dataCopy);
      //       }
      //     };
      //     return (
      //       <div className="d-flex gap-4">
      //         <span
      //           className="btn btn-lg btn-icon btn-circle btn-primary "
      //           onClick={onEditItemClick}
      //         >
      //           {/* <i className="fas fa-2x  fa-pencil"></i> */}
      //           Edit
      //         </span>
      //         <span
      //           className="btn btn-lg btn-icon btn-circle btn-danger"
      //           onClick={onDeleteItemClick}
      //         >
      //           {/* <i className="fas fa-2x  fa-trash-can"></i> */}
      //           Delete
      //         </span>
      //       </div>
      //     );
      //   },
      // },
    ],
    [userinfo]
  );
  const hiddendetailappColumns = ["userid"];
  return (
    <div>
      <h3 align="center">Connect Apps</h3>
      <div className="d-flex justify-content-end">
        <Button
          onClick={() => handleAddClickApps()}
          className="btn btn-lg mb-2"
        >
          Add
          {/* <span className="fa-stack fa-2x">
          <i className="far fa-circle fa-stack-2x"></i>
          <i className="fa fa-plus fa-stack-1x"></i>
        </span> */}
        </Button>
      </div>
      <GenericListTable
        gcolumns={appColumns}
        gdata={data.length > 0 ? JSON.stringify(data) : ""}
        ghiddencolumns={hiddenappColumns}
        gplaceholder={"Search Apps.."}
        gsearchDisabled={true}
      />
      {errorMsg.length > 0 && (
        <div>
          <small
            className="form-check-label text-success fw-bold"
            htmlFor="errorMsg"
          ></small>
          <small
            className="form-check-label text-danger fw-bold"
            htmlFor="errorMsg"
          >
            {errorMsg}
          </small>
        </div>
      )}
      <div className="p-1"></div>
      {isDeleteApp && (
        <SweetAlert
          warning
          style={{ color: "black" }}
          title=""
          confirmBtnText="Delete"
          cancelBtnText="No"
          showCancel={true}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        >
          <div>Do you want to delete ?</div>
        </SweetAlert>
      )}
      {isDeleted && (
        <SweetAlert
          success
          style={{ color: "black" }}
          title=""
          confirmBtnText="Ok"
          onConfirm={handleConfirmDeleted}
        >
          <div>App deleted successfully.</div>
        </SweetAlert>
      )}
      {isEditApp && list && app && RelationshipList && (
        <Modal
          scrollable={true}
          show={isEditApp}
          onHide={() => {
            // setIsEditApp(false);
            // setApp(initialappstate);
            goToHomePage();
          }}
          size="lg"
          centered
          backdrop="static"
          className="modaldarkopacity"
        >
          <Modal.Header className="fw-bold h4">
            Edit app connection{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => {
                // setIsEditApp(false);
                // setApp(initialappstate);
                goToHomePage();
              }}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="row p-1">
              <div className="col-lg-12">
                <Form>
                  {loading && <Loading />}
                  {showGeneralSection && (
                    <div id="generalsection">
                      <div name="basicinfo" className="h6 fw-bolder mt-4">
                        General section
                      </div>
                      <hr />
                    </div>
                  )}
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Form.Label>
                        Source app name
                        <label className="text-danger">*</label>
                      </Form.Label>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-7">
                      <Select
                        name="appname"
                        options={applist}
                        isDisabled={true}
                        className="form-control"
                        isSearchable={true}
                        styles={customstyles}
                        value={applist.filter(
                          (record) => record.value === app.appname
                        )}
                        onChange={(val) => {
                          handleDropDownChange(
                            {
                              target: {
                                name: "appname",
                                value: val.value,
                              },
                            },
                            "contact"
                          );
                        }}
                      ></Select>
                      {errors.appname.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="appname"
                        >
                          {errors.appname}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Form.Label>Environment</Form.Label>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-7">
                      <Select
                        name="appenvironment"
                        options={environmentlist}
                        isDisabled={true}
                        className="form-control"
                        isSearchable={true}
                        styles={customstyles}
                        value={environmentlist.filter(
                          (record) => record.value === app.appenvironment
                        )}
                        onChange={(val) => {
                          handleDropDownChange(
                            {
                              target: {
                                name: "appenvironment",
                                value: val.value,
                              },
                            },
                            "contact"
                          );
                        }}
                      ></Select>
                      {errors.appenvironment.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="appenvironment"
                        >
                          {errors.appenvironment}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Form.Label>Relationship</Form.Label>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-7">
                      <Select
                        name="apprelationship"
                        options={RelationshipList}
                        className="form-control"
                        isSearchable={true}
                        styles={customstyles}
                        value={RelationshipList.filter(
                          (record) => record.value === app.apprelationship
                        )}
                        onChange={(val) => {
                          handleDropDownChange(
                            {
                              target: {
                                name: "apprelationship",
                                value: val.value,
                              },
                            },
                            "contact"
                          );
                        }}
                      ></Select>
                      {errors.apprelationship.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="apprelationship"
                        >
                          {errors.apprelationship}
                        </small>
                      )}
                    </div>
                  </div>
                  {/* <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Form.Label>Auto approval</Form.Label>
                      <label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-7 col-md-7 col-lg-7">
                      <Select
                        name="appautoapproval"
                        options={AutoApprovalList}
                        className="form-control"
                        isSearchable={true}
                        styles={customstyles}
                        value={AutoApprovalList.filter(
                          (record) => record.value === app.appautoapproval
                        )}
                        onChange={(val) => {
                          handleDropDownChange(
                            {
                              target: {
                                name: "appautoapproval",
                                value: val.value,
                              },
                            },
                            "contact"
                          );
                        }}
                      ></Select>
                      {errors.appautoapproval.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="appautoapproval"
                        >
                          {errors.appautoapproval}
                        </small>
                      )}
                    </div>
                  </div> */}
                  <div className="row p-1">
                    <div className="col-sm-12 col-md-3 col-lg-3">
                      <Form.Label>APP display name</Form.Label>
                      <label className="text-danger">*</label>
                    </div>
                    <div className="col-sm-12 col-md-7 col-lg-7">
                      <input
                        type="text"
                        name="appdisplayname"
                        id="appdisplayname"
                        className="form-control"
                        onChange={(e) => handleChange(e, "appuser")}
                        value={appuserinforow?.appdisplayname}
                      />
                      {errorsisconnect.appdisplayname.length > 0 && (
                        <small
                          className="form-check-label text-danger"
                          htmlFor="appdisplayname"
                        >
                          {errorsisconnect.appdisplayname}
                        </small>
                      )}
                    </div>
                  </div>
                  {app.appname.toLowerCase() === quickbookappname && (
                    <div className="row p-1">
                      <span className="col-sm-3 col-md-3 col-lg-3">
                      Include Sub Customer
                        <input
                          type="checkbox"
                          name="isSubCustomerAllowed"
                          id="isSubCustomerAllowed"
                          className="ms-1"
                          onChange={(e) => {
                            setApp({
                              ...app,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                          checked={app.isSubCustomerAllowed}
                        />
                      </span>
                    </div>
                  )}
                  {(app.appname.toLowerCase() === quickbookappname ||
                    procoreappname ||
                    salesforceappname ||
                    devenv === "dev") && (
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Account id</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appaccountid"
                            id="appaccountid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appaccountid}
                          />
                          {errorsisconnect.appaccountid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appaccountid"
                            >
                              {errorsisconnect.appaccountid}
                            </small>
                          )}
                        </div>
                      </div>
                    )}

                  {showEndpointSection && (
                    <div id="accountsection">
                      {/* <div name="basicinfo" className="h6 fw-bolder mt-4">
                        Account Section
                      </div>
                      <hr /> */}

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label> Account user id</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appaccountuserid"
                            id="appaccountuserid"
                            className="form-control"
                            onChange={(e) => handleChange(e, "app")}
                            value={app.appaccountuserid}
                          />
                          {/* {showEndpointSection && errors.appdisplayname.length > 0 && (
                    <small
                      className="form-check-label text-danger"
                      htmlFor="appdisplayname"
                    >
                      {errors.appdisplayname}
                    </small>
                  )} */}
                        </div>
                      </div>
                    </div>
                  )}
                  {showSecuritySection && (
                    <div id="securitysection">
                      {/* <div name="basicinfo" className="h6 fw-bolder mt-4">
                        Security Section
                      </div>
                      <hr /> */}

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Client id</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appclientid"
                            id="appclientid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appclientid}
                          />
                          {errorsisconnect.appclientid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appclientid"
                            >
                              {errorsisconnect.appclientid}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Client secret</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appclientsecret"
                            id="appclientsecret"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appclientsecret}
                          />
                          {errorsisconnect.appclientsecret.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appclientsecret"
                            >
                              {errorsisconnect.appclientsecret}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Redirect url</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appredirecturi"
                            id="appredirecturi"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appredirecturi}
                          />
                          {errorsisconnect.appredirecturi.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appredirecturi"
                            >
                              {errorsisconnect.appredirecturi}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App url</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appurl"
                            id="appurl"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appurl}
                          />
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>API version</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apiversion"
                            id="apiversion"
                            className="form-control"
                            onChange={handleChange}
                            value={app.apiversion}
                            placeholder="API version"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMicrosoftTenantSection && (
                    <div id="sharepointsection">
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Tenant id</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="tenantid"
                            id="tenantid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.tenantid}
                            placeholder="Tenant ID"
                          />
                          {errorssharepoint.tenantid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="tenantid"
                            >
                              {errorssharepoint.tenantid}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Tenant name</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="tenantname"
                            id="tenantname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.tenantname}
                            placeholder="Tenant name"
                          />
                          {errorssharepoint.tenantname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="tenantname"
                            >
                              {errorssharepoint.tenantname}
                            </small>
                          )}
                        </div>
                      </div>

                      {!showEntraIDSection && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <Form.Label>Site name</Form.Label>
                            <label className="text-danger">*</label>
                          </div>
                          <div className="col-sm-12 col-md-7 col-lg-7">
                            <input
                              type="text"
                              name="sitename"
                              id="sitename"
                              className="form-control"
                              onChange={handleChange}
                              value={app.sitename}
                              placeholder="Site name"
                            />
                            {errorssharepoint.sitename.length > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="sitename"
                              >
                                {errorssharepoint.sitename}
                              </small>
                            )}
                          </div>
                        </div>
                      )}
                      {!showEntraIDSection && (
                        <div className="row p-1">
                          <div className="col-sm-12 col-md-3 col-lg-3">
                            <Form.Label>Site library</Form.Label>
                            <label className="text-danger">*</label>
                          </div>
                          <div className="col-sm-12 col-md-7 col-lg-7">
                            <input
                              type="text"
                              name="sitelibrary"
                              id="sitelibrary"
                              className="form-control"
                              onChange={handleChange}
                              value={app.sitelibrary}
                              placeholder="Site library"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {showTokenSection && (
                    <div id="tokensection">
                      {/* <div name="tokeninfo" className="h6 fw-bolder mt-4">
                        Token Section
                      </div>
                      <hr /> */}
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App host name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appresthostname"
                            id="appresthostname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appresthostname}
                          />
                          {errorsisconnect.appresthostname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appresthostname"
                            >
                              {errorsisconnect.appresthostname}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App token host name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appaccesstokenhostname"
                            id="appaccesstokenhostname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appaccesstokenhostname}
                          />
                          {errorsisconnect.appaccesstokenhostname.length >
                            0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="appaccesstokenhostname"
                              >
                                {errorsisconnect.appaccesstokenhostname}
                              </small>
                            )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Access token</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apptoken"
                            id="apptoken"
                            className="form-control"
                            disabled="true"
                            onChange={handleChange}
                            value={appuserinforow?.apptoken}
                          />
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Refresh token</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apprefreshtoken"
                            id="apprefreshtoken"
                            disabled="true"
                            className="form-control"
                            onChange={handleChange}
                            value={appuserinforow?.apprefreshtoken}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showEndpointSection && (
                    <div id="endpointsection" hidden={true}>
                      {/* <div name="endpointinfo" className="h6 fw-bolder mt-4">
                        Endpoint Section
                      </div>
                      <hr /> */}
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint type</Form.Label>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="endpointtype"
                            options={endpointtypelookuplist}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={endpointtypelookuplist.filter(
                              (record) =>
                                record.value === apiendpointrow.endpointtype
                            )}
                            onChange={(val) => {
                              handleDropDownChange(
                                {
                                  target: {
                                    name: "endpointtype",
                                    value: val.value,
                                  },
                                },
                                "endpoint"
                              );
                            }}
                          ></Select>
                          {errorsendpoint.endpointtype.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointtype"
                            >
                              {errorsendpoint.endpointtype}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointname"
                            id="endpointname"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointname}
                          />
                          {errorsendpoint.endpointname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointname"
                            >
                              {errorsendpoint.endpointname}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint value</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointvalue"
                            id="endpointvalue"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointvalue}
                          />
                          {errorsendpoint.endpointvalue.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointvalue"
                            >
                              {errorsendpoint.endpointvalue}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint secret</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointsecret"
                            id="endpointsecret"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointsecret}
                          />
                          {errorsendpoint.endpointsecret.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointsecret"
                            >
                              {errorsendpoint.endpointsecret}
                            </small>
                          )}
                        </div>
                        <div className="row p-1">
                          <div className="d-flex gap-3 justify-content-end align-items-center m-2">
                            <Button
                              color="default"
                              id="clear"
                              size="md"
                              onClick={clearenvironmentinfo}
                            >
                              Clear
                            </Button>
                            <Button
                              className=""
                              color="default"
                              size="md"
                              onClick={addendpointinfo}
                            >
                              Add to list
                            </Button>
                            {errorsendpoint.endpointminval > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="endpointminval"
                              >
                                {errorsendpoint.endpointminval}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="row p-1 mt-3">
                          <GenericSimpleTable
                            gcolumns={editappColumns}
                            gdata={
                              endpointlist.length > 0
                                ? JSON.stringify(endpointlist)
                                : ""
                            }
                            ghiddencolumns={hiddeneditappColumns}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {isAppIsUpdated && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={goToHomePage}
                      >
                        <div>App updated successfully</div>
                      </SweetAlert>
                    )}

                    {isSuccessForConnect && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={handleSucessSweetAlertForConnect}
                      >
                        <div>
                          {existAppForConnect.appname} Updated successfully
                        </div>
                      </SweetAlert>
                    )}
                    {/* {isSuccessForSave && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={handleSucessSweetAlertForSave}
                      >
                        <div>
                          {existAppForSave.appname} was updated successfully
                        </div>
                      </SweetAlert>
                    )} */}

                    {isShowDuplicatevalidation && (
                      <SweetAlert
                        warning
                        style={{ color: "black" }}
                        title="Validation Info"
                        onConfirm={handleSweetAlertValidationMessage}
                      >
                        <div>{validatemsg}</div>
                      </SweetAlert>
                    )}
                    {isShowError && (
                      <SweetAlert
                        danger
                        style={{ color: "black" }}
                        title="Error Info"
                        onConfirm={handleSweetAlertValidationMessage}
                      >
                        <div>{validatemsg}</div>
                      </SweetAlert>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <div className="d-flex gap-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      // setIsEditApp(false);
                      // setApp(initialappstate);
                      goToHomePage();
                    }}
                  >
                    Close
                  </Button>
                  {app.appname.toLowerCase() !== contactmasterappname ||
                    app.appname.toLowerCase() !== projectmasterappname ||
                    (app.appname.toLowerCase() !== entitymasterappname && (
                      <Button
                        variant="primary"
                        id="save"
                        onClick={() => onDelete(userinfo?.appid)}
                        className="btn btn-success"
                      >
                        Disable
                      </Button>
                    ))}
                  <Button
                    variant="primary"
                    id="save"
                    onClick={() => btnSaveTrue()}
                    className="btn btn-success"
                  >
                    Save
                  </Button>
                  {isconnect === "true" ? (
                    <Button
                      variant="primary"
                      id="procoreToken"
                      onClick={() => authorizeUriTrue()}
                      className="btn btn-success"
                    >
                      {ids === "0" ? "Connect" : "Reconnect"}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isDetailApp && (
        <Modal
          scrollable={true}
          show={isDetailApp}
          onHide={() => {
            setIsDetailApp(false);
          }}
          animation={true}
          backdrop="static"
          size="lg"
          centered
          className="modaldarkopacity"
        >
          <Modal.Header className="fw-bold h4">
            Detail connect app{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => setIsDetailApp(false)}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container p-3">
              <div className="row ">
                <div className="col-lg-12">
                  <div>
                    <div className=" row p-1">
                      {/* <div className="col-sm-12 col-md-4 col-lg-2">
                        <img
                          src={
                            app.applogo === "" || app.applogo === undefined
                              ? "../assets/img/noimg.png"
                              : app.applogo
                          }
                          className="rounded-circle mx-auto d-block mw-100 mt-2 d-flex justify-content-end"
                          width="80"
                          height="80"
                        />
                      </div> */}
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <span className="h4 fw-bold d-flex justify-content-start mt-2">
                          {appuserinforow?.appdisplayname}
                        </span>
                        <span className=" d-flex justify-content-start mt-2 ">
                          <em>
                            {" "}
                            Created on{" "}
                            {Moment(app?.createdOn).format(
                              appConstants.DATEFORMAT
                            )}
                          </em>
                        </span>
                      </div>
                    </div>
                    {loading && <Loading />}
                    {showGeneralSection && (
                      <div className=" row p-1 mt-3">
                        <div className="h4 fw-bold"> General section </div>
                        <hr />
                        {app.appname !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Source app name</div>
                            <div className="col-lg-8">{app.appname}</div>
                          </div>
                        )}
                        {app.appenvironment !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4"> Environment</div>
                            <div className="col-lg-8">{app.appenvironment}</div>
                          </div>
                        )}
                        {app.apprelationship !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4"> Relationship</div>
                            <div className="col-lg-8">
                              {app.apprelationship}
                            </div>
                          </div>
                        )}
                        {/* {app.appautoapproval !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Auto approval</div>
                            <div className="col-lg-8">
                              {app.appautoapproval}
                            </div>
                          </div>
                        )} */}
                      </div>
                    )}
                    {showAccountSection && (
                      <div className=" row p-1 mt-3">
                        {/* <div className="h4 fw-bold"> Account Section </div> */}
                        <hr />
                        {app.appaccountid !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Account id</div>
                            <div className="col-lg-8">{app.appaccountid}</div>
                          </div>
                        )}
                      </div>
                    )}
                    {showSecuritySection && (
                      <div className=" row p-1 mt-3">
                        {/* <div className="h4 fw-bold "> Security Section </div> */}
                        <hr />
                        {app.appclientid !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Client id</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {app.appclientid}
                            </div>
                          </div>
                        )}
                        {app.appclientsecret !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Client secret</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {app.appclientsecret}
                            </div>
                          </div>
                        )}
                        {app.appredirecturi !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Redirect url</div>
                            <div className="col-lg-8 ">
                              {app.appredirecturi}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {showTokenSection && (
                      <div className=" row p-1 mt-3">
                        {/* <div className="h4 fw-bold"> Token Section</div> */}
                        <hr />
                        {app.appresthostname !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">App host name</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {app.appresthostname}
                            </div>
                          </div>
                        )}
                        {app.appaccesstokenhostname !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">App token host name</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {app.appaccesstokenhostname}
                            </div>
                          </div>
                        )}
                        {appuserinforow.apptoken !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Access token</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {appuserinforow.apptoken}
                            </div>
                          </div>
                        )}
                        {appuserinforow.apprefreshtoken !== "" && (
                          <div className="row mt-3">
                            <div className="col-lg-4">Refresh token</div>
                            <div className="col-lg-8 overflow-hidden ">
                              {appuserinforow.apprefreshtoken}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {showEndpointSection && (
                      <div className=" row p-1 mt-3">
                        {/* <div className="h4 fw-bold"> Endpoint Section </div> */}
                        <hr />
                        <div className="row p-1 mt-3">
                          <GenericSimpleTable
                            gcolumns={detailappColumns}
                            gdata={
                              endpointlist.length > 0
                                ? JSON.stringify(endpointlist)
                                : ""
                            }
                            ghiddencolumns={hiddendetailappColumns}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-3 justify-content-end align-items-center m-2">
              <Button variant="secondary" onClick={() => setIsDetailApp(false)}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {isAddApp && (
        <Modal
          scrollable={true}
          show={isAddApp}
          onHide={() => {
            // setIsAddApp(false);
            // setApp(initialappstate);
            goToHomePage();
          }}
          size="lg"
          centered
          backdrop="static"
          className="modaldarkopacity"
        >
          <Modal.Header className="fw-bold h4">
            Add app connection{" "}
            <a
              style={{ "text-decoration": "none" }}
              onClick={() => {
                // setIsAddApp(false);
                // setApp(initialappstate);
                goToHomePage();
              }}
              className="col d-flex justify-content-end text-dark"
            >
              <i class="fa-solid fa-xmark"></i>
            </a>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="row p-1">
              <div className="col-lg-12">
                <Form>
                  {loading && <Loading />}
                  <div className="col-lg-8"></div>
                  {showGeneralSection && (
                    <div id="generalsection">
                      <div name="basicinfo" className="h6 fw-bolder mt-4">
                        General section
                      </div>
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          Source app name
                          <label className="text-danger">*</label>
                        </div>

                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="appname"
                            options={applist}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={applist.filter(
                              (record) => record.value === app.appname
                            )}
                            onChange={(val) => {
                              handleAddDropDownChange(
                                {
                                  target: {
                                    name: "appname",
                                    value: val.value,
                                  },
                                },
                                "contact"
                              );
                            }}
                          ></Select>
                          {errors.appname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appname"
                            >
                              {errors.appname}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          Environment
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="appenvironment"
                            options={environmentlist}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={environmentlist.filter(
                              (record) => record.value === app.appenvironment
                            )}
                            onChange={(val) => {
                              handleAddDropDownChange(
                                {
                                  target: {
                                    name: "appenvironment",
                                    value: val.value,
                                  },
                                },
                                "contact"
                              );
                            }}
                          ></Select>
                          {errors.appenvironment.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appenvironment"
                            >
                              {errors.appenvironment}
                            </small>
                          )}
                        </div>
                      </div>

                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Relationship</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="apprelationship"
                            options={RelationshipList}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={RelationshipList.filter(
                              (record) => record.value === app.apprelationship
                            )}
                            onChange={(val) => {
                              handleDropDownChange(
                                {
                                  target: {
                                    name: "apprelationship",
                                    value: val.value,
                                  },
                                },
                                "contact"
                              );
                            }}
                          ></Select>
                          {errors.apprelationship.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="apprelationship"
                            >
                              {errors.apprelationship}
                            </small>
                          )}
                        </div>
                      </div>
                      {/* <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Auto approval</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="appautoapproval"
                            options={AutoApprovalList}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={AutoApprovalList.filter(
                              (record) => record.value === app.appautoapproval
                            )}
                            onChange={(val) => {
                              handleDropDownChange(
                                {
                                  target: {
                                    name: "appautoapproval",
                                    value: val.value,
                                  },
                                },
                                "contact"
                              );
                            }}
                          ></Select>
                          {errors.appautoapproval.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appautoapproval"
                            >
                              {errors.appautoapproval}
                            </small>
                          )}
                        </div>
                      </div> */}
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>APP display name</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appdisplayname"
                            id="appdisplayname"
                            className="form-control"
                            onChange={(e) => handleChange(e, "appuser")}
                            placeholder="APP Display Name"
                            value={appuserinforow.appdisplayname}
                          />
                          {errorsisconnect.appdisplayname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appdisplayname"
                            >
                              {errorsisconnect.appdisplayname}
                            </small>
                          )}
                        </div>
                      </div>
                      {app.appname.toLowerCase() === quickbookappname && (
                        <div className="row p-1">
                          <span className="col-sm-3 col-md-3 col-lg-3">
                          Include Sub Customer
                            <input
                              type="checkbox"
                              name="isSubCustomerAllowed"
                              id="isSubCustomerAllowed"
                              className="ms-1"
                              onChange={(e) => {
                                setApp({
                                  ...app,
                                  [e.target.name]: e.target.checked,
                                });
                              }}
                              checked={app.isSubCustomerAllowed}
                            />
                          </span>
                        </div>
                      )}
                      {(app.appname.toLowerCase() === quickbookappname ||
                        procoreappname ||
                        salesforceappname ||
                        devenv === "dev") && (
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <Form.Label>Account id</Form.Label>
                            </div>
                            <div className="col-sm-7 col-md-7 col-lg-7">
                              <input
                                type="text"
                                name="appaccountid"
                                id="appaccountid"
                                className="form-control"
                                onChange={handleChange}
                                placeholder="Account ID"
                                value={app.appaccountid}
                              />
                              {errorsisconnect.appaccountid.length > 0 && (
                                <small
                                  className="form-check-label text-danger"
                                  htmlFor="appaccountid"
                                >
                                  {errorsisconnect.appaccountid}
                                </small>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                  {showEndpointSection && (
                    <div id="accountsection">
                      {/* <div name="accountinfo" className="h6 fw-bolder mt-4">
                        Account Section
                      </div> */}
                      <hr />{" "}
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label> Account user id</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appaccountuserid"
                            id="appaccountuserid"
                            className="form-control"
                            onChange={(e) => handleChange(e, "app")}
                            value={app.appaccountuserid}
                            placeholder="Account User ID"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {showSecuritySection && (
                    <div id="securitysection">
                      {/* <div name="securityinfo" className="h6 fw-bolder mt-4">
                        Security Section
                      </div> */}
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Client id</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appclientid"
                            id="appclientid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appclientid}
                            placeholder="Client ID"
                          />
                          {errorsisconnect.appclientid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appclientid"
                            >
                              {errorsisconnect.appclientid}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Client secret</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appclientsecret"
                            id="appclientsecret"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appclientsecret}
                            placeholder="Client Secret"
                          />
                          {errorsisconnect.appclientsecret.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appclientsecret"
                            >
                              {errorsisconnect.appclientsecret}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Redirect url</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appredirecturi"
                            id="appredirecturi"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appredirecturi}
                            placeholder="Redirect URL"
                          />
                          {errorsisconnect.appredirecturi.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appredirecturi"
                            >
                              {errorsisconnect.appredirecturi}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App url</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appurl"
                            id="appurl"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appurl}
                            placeholder="App URL"
                          />
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>API version</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apiversion"
                            id="apiversion"
                            className="form-control"
                            onChange={handleChange}
                            value={app.apiversion}
                            placeholder="API version"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showMicrosoftTenantSection && (
                    <div id="sharepointsection">
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Tenant id</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="tenantid"
                            id="tenantid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.tenantid}
                            placeholder="Tenant ID"
                          />
                          {errorssharepoint.tenantid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="tenantid"
                            >
                              {errorssharepoint.tenantid}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Tenant name</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="tenantname"
                            id="tenantname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.tenantname}
                            placeholder="Tenant name"
                          />
                          {errorssharepoint.tenantname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="tenantid"
                            >
                              {errorssharepoint.tenantname}
                            </small>
                          )}
                        </div>
                      </div>

                      {!showEntraIDSection && (
                        <div>
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <Form.Label>Site name</Form.Label>
                              <label className="text-danger">*</label>
                            </div>
                            <div className="col-sm-12 col-md-7 col-lg-7">
                              <input
                                type="text"
                                name="sitename"
                                id="sitename"
                                className="form-control"
                                onChange={handleChange}
                                value={app.sitename}
                                placeholder="Site name"
                              />
                              {errorssharepoint.sitename.length > 0 && (
                                <small
                                  className="form-check-label text-danger"
                                  htmlFor="sitename"
                                >
                                  {errorssharepoint.sitename}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row p-1">
                            <div className="col-sm-12 col-md-3 col-lg-3">
                              <Form.Label>Site library</Form.Label>
                              <label className="text-danger">*</label>
                            </div>
                            <div className="col-sm-12 col-md-7 col-lg-7">
                              <input
                                type="text"
                                name="sitelibrary"
                                id="sitelibrary"
                                className="form-control"
                                onChange={handleChange}
                                value={app.sitelibrary}
                                placeholder="Site library"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {/* <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Subsite ID</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="subsiteid"
                            id="subsiteid"
                            className="form-control"
                            onChange={handleChange}
                            value={app.subsiteid}
                            placeholder="Sharepoint Subsite ID"
                          />
                          {errorssharepoint.subsiteid.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="subsiteid"
                            >
                              {errorssharepoint.subsiteid}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Subsite Name</Form.Label>
                          <label className="text-danger">*</label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="subsitename"
                            id="subsitename"
                            className="form-control"
                            onChange={handleChange}
                            value={app.subsitename}
                            placeholder="Sharepoint Subsite Name"
                          />
                          {errorssharepoint.subsitename.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="subsitename"
                            >
                              {errorssharepoint.subsitename}
                            </small>
                          )}
                        </div>
                      </div> */}
                    </div>
                  )}
                  {showTokenSection && (
                    <div id="tokensection">
                      {/* <div name="tokeninfo" className="h6 fw-bolder mt-4">
                        Token Section
                      </div> */}
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App host name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appresthostname"
                            id="appresthostname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appresthostname}
                            placeholder="App Host Name"
                          />
                          {errorsisconnect.appresthostname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="appresthostname"
                            >
                              {errorsisconnect.appresthostname}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>App token host name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="appaccesstokenhostname"
                            id="appaccesstokenhostname"
                            className="form-control"
                            onChange={handleChange}
                            value={app.appaccesstokenhostname}
                            placeholder="App Token Host Name"
                          />
                          {errorsisconnect.appaccesstokenhostname.length >
                            0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="appaccesstokenhostname"
                              >
                                {errorsisconnect.appaccesstokenhostname}
                              </small>
                            )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Access token</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apptoken"
                            id="apptoken"
                            className="form-control"
                            disabled="true"
                            onChange={handleChange}
                            value={appuserinforow.apptoken}
                            placeholder="Access Token"
                          />
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Refresh token</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="apprefreshtoken"
                            id="apprefreshtoken"
                            disabled="true"
                            className="form-control"
                            onChange={handleChange}
                            value={appuserinforow.apprefreshtoken}
                            placeholder="Refresh Token"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {showEndpointSection && (
                    <div id="endpointsection" hidden={true}>
                      {/* <div name="endpointinfo" className="h6 fw-bolder mt-4">
                        Endpoint Section
                      </div> */}
                      <hr />
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint type</Form.Label>
                        </div>
                        <div className="col-sm-7 col-md-7 col-lg-7">
                          <Select
                            name="endpointtype"
                            options={endpointtypelookuplist}
                            className="form-control"
                            isSearchable={true}
                            styles={customstyles}
                            value={endpointtypelookuplist.filter(
                              (record) =>
                                record.value === apiendpointrow.endpointtype
                            )}
                            onChange={(val) => {
                              handleDropDownChange(
                                {
                                  target: {
                                    name: "endpointtype",
                                    value: val.value,
                                  },
                                },
                                "endpoint"
                              );
                            }}
                          ></Select>
                          {errorsendpoint.endpointtype.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointtype"
                            >
                              {errorsendpoint.endpointtype}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint name</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointname"
                            id="endpointname"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointname}
                            placeholder="Endpoint Name"
                          />
                          {errorsendpoint.endpointname.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointname"
                            >
                              {errorsendpoint.endpointname}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint value</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointvalue"
                            id="endpointvalue"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointvalue}
                            placeholder="Endpoint Value"
                          />
                          {errorsendpoint.endpointvalue.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointvalue"
                            >
                              {errorsendpoint.endpointvalue}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row p-1">
                        <div className="col-sm-12 col-md-3 col-lg-3">
                          <Form.Label>Endpoint secret</Form.Label>
                        </div>
                        <div className="col-sm-12 col-md-7 col-lg-7">
                          <input
                            type="text"
                            name="endpointsecret"
                            id="endpointsecret"
                            className="form-control"
                            onChange={(e) => handleChange(e, "endpoint")}
                            value={apiendpointrow.endpointsecret}
                            placeholder="Endpoint Secret"
                          />
                          {errorsendpoint.endpointsecret.length > 0 && (
                            <small
                              className="form-check-label text-danger"
                              htmlFor="endpointsecret"
                            >
                              {errorsendpoint.endpointsecret}
                            </small>
                          )}
                        </div>
                        <div className="row p-1">
                          <div className="d-flex gap-3 justify-content-end align-items-center m-2">
                            <Button
                              color="default"
                              id="clear"
                              size="md"
                              onClick={clearenvironmentinfo}
                            >
                              Clear
                            </Button>
                            <Button
                              className=""
                              color="default"
                              size="md"
                              onClick={addendpointinfo}
                            >
                              Add to list
                            </Button>

                            {errorsendpoint.endpointminval > 0 && (
                              <small
                                className="form-check-label text-danger"
                                htmlFor="endpointminval"
                              >
                                {errorsendpoint.endpointminval}
                              </small>
                            )}
                          </div>
                        </div>
                        <hr />
                        <div className="row p-1 mt-3">
                          <GenericSimpleTable
                            gcolumns={addappColumns}
                            gdata={
                              endpointlist.length > 0
                                ? JSON.stringify(endpointlist)
                                : ""
                            }
                            ghiddencolumns={hiddenaddappColumns}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    {isAppIsCreated && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={goToHomePage}
                      >
                        <div>App created successfully</div>
                      </SweetAlert>
                    )}
                    {isAppIsUpdated && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={goToHomePage}
                      >
                        <div>App updated successfully</div>
                      </SweetAlert>
                    )}

                    {isSuccessForConnect && (
                      <SweetAlert
                        success
                        style={{ color: "black" }}
                        title=""
                        onConfirm={handleSucessSweetAlertForConnect}
                      >
                        <div>
                          {existAppForConnect.appname} Updated successfully
                        </div>
                      </SweetAlert>
                    )}

                    {isShowDuplicatevalidation && (
                      <SweetAlert
                        warning
                        style={{ color: "black" }}
                        title="Validation Info"
                        onConfirm={handleSweetAlertValidationMessage}
                      >
                        <div>{validatemsg}</div>
                      </SweetAlert>
                    )}
                    {isShowError && (
                      <SweetAlert
                        danger
                        style={{ color: "black" }}
                        title="Error Info"
                        onConfirm={handleSweetAlertValidationMessage}
                      >
                        <div>{validatemsg}</div>
                      </SweetAlert>
                    )}
                    {iscommandpostaccountinfo && (
                      <SweetAlert
                        danger
                        style={{ color: "black" }}
                        title="Commandpost user is undefined"
                        onConfirm={handlecommandpostaccountinfo}
                      >
                        <div></div>
                      </SweetAlert>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {" "}
            <div className="row p-1">
              {" "}
              <div className=" d-flex justify-content-end mt-3 col-sm-12 col-md-12 col-lg-12">
                <div className="d-flex gap-2">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      // setIsAddApp(false);
                      // setApp(initialappstate);
                      goToHomePage();
                    }}
                  >
                    Close
                  </Button>
                  {isconnect === "true" ? (
                    <Button
                      variant="primary"
                      id="procoreToken"
                      onClick={() => addauthorizeUriTrue()}
                      className="btn btn-success"
                    >
                      Connect
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      id="save"
                      onClick={() => btnAddSaveTrue()}
                      className="btn btn-success"
                    >
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default Listapps;
